// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import Example from "https://framer.com/m/framer/Example.js@^1.0.0"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Leads_Form(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <form
            action="https://submit-form.com/cj9w9CJcJ"
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "'General Sans', sans-serif",
                color: "black",
                padding: "12px",
                backgroundColor: "transparent",
                borderRadius: "10px",
                width: "100%", // Ensure the form takes the full width
                height: "72px", // Set a fixed height for the form
            }}
        >
            <input
                type="hidden"
                name="_redirect"
                value="https://wentworthprotection.co.uk/thankyou"
            />
            <input
                type="text"
                id="number"
                name="phonenumber"
                placeholder="Phone Number"
                required
                style={{
                    flexGrow: 1,
                    height: "100%", // The input field will fill the form's height
                    padding: "0 16px", // Padding inside the input field
                    marginRight: "8px",
                    background: "#ecf0f1",
                    border: "none",
                    borderRadius: "10px",
                    fontSize: "18px", // Increase the font size
                }}
            />
            <input
                type="submit"
                value="Call Me Back"
                style={{
                    padding: "0 32px", // Padding inside the button
                    backgroundColor: "#EA7541",
                    color: "white",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "10px",
                    cursor: "pointer",
                    fontSize: "18px", // Increase the font size
                    height: "100%", // The button will fill the form's height
                }}
            />
        </form>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
